$(document).ready(function() {
	$( "#mot-cle-commune" ).autocomplete({
        source: "/csa_fr/autocomplete?recherche=2",
        minLength: 2,
        select: function( event, ui ) {
			$(this).val(ui.item.value);
			$(this).parent('form').submit();
        }
    });

	$('.operator_popup').click(function() {
		$('.popup_block').fadeOut();
		var link = $(this).find('a');
		var popup = $(this).find('.popup_block');
		if (popup.css('display') == 'block') {
			popup.fadeOut();
		}
		else {
			$('.popup_block').fadeOut();
			showPopup(link, popup);
		}
		return false;
	});
	$('.close').click(function() {
		var popup = $(this).parent('.popup_block');
		popup.fadeOut(function() {});
		return false;
	});

});

function showPopup(link, popup) {
	var popID = link.attr('rel'); //Trouver la pop-up correspondante
	var popURL = link.attr('href'); //Retrouver la largeur dans le href

	//Récupérer les variables depuis le lien
	var query= popURL.split('?');
	var dim= query[1].split('&');
	var popWidth = dim[0].split('=')[1]; //La première valeur du lien

	//Faire apparaitre la pop-up et ajouter le bouton de fermeture
	popup.fadeIn().css({
		'width': Number(popWidth)
	})
	.prepend('');
}

function trier(colonne) {
    if (document.requete.colonne.value == colonne)
    	document.requete.tri.value = (document.requete.tri.value == 'asc')?'desc':'asc';
    document.requete.colonne.value 	= colonne + '';
    document.requete.target         = '_self';
    document.requete.submit();
}