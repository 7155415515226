$(document).ready(function() {
	$( "#mot-cle-radio" ).autocomplete({
        source: "/csa_fr/autocomplete?recherche=1",
        minLength: 2,
        select: function( event, ui ) {
			$(this).val(ui.item.value);
			$(this).parent('form').submit();
        }
    });
});

function departement(dpt) {
	var listeDpt = new Array(971,972,973,974,975,985,986,988);
	var valide = true;

	for( i=0 ; i<=listeDpt.length; i++ ){
		if (dpt == listeDpt[i]) valide = false;
	}
	if (valide){
		document.formStation.dptCarte.value=dpt;
		document.formStation.recherche.value=0;
		document.formStation.submit();
	} else {
		alert("Les données de ce département sont en cours de réactualisation.");
	}
}

function recherche1() {
	if(document.formStation.station.value==''){
		alert("Vous devez saisir un mot clé !");
		return false;
	} else {
		return true;
	}
}

function recherche2() {
	if((document.formGeo.dpt.selectedIndex==0)&&(document.formGeo.zone.selectedIndex==0)){
		alert("Vous devez choisir un département ou une agglomération !");
		return false;
	} else {
		return true;
	}
}

function trier(colonne) {
    if (document.requete.colonne.value == colonne)
    	document.requete.tri.value = (document.requete.tri.value == 'asc')?'desc':'asc';
    document.requete.colonne.value    = colonne+'';
    document.requete.page.value = 1;
    document.requete.target         = '_self';
    document.requete.submit();
}
